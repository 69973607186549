.ecoway-page {
    font-family: Arial, sans-serif;
    background-color: #282c34;

  }
  
  .ecoway-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .content {
    padding: 20px;
  }
  
  section {
    margin-bottom: 40px;
  }
  
  h1, h2 {
    color: white;
  }
  
  p, li {
    color: white;
  }
  
  .btn-home, .btn-demo {
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .btn-home {
    background-color: #4CAF50;
    color: white;
  }
  
  .btn-demo {
    background-color: #008CBA;
    color: white;
  }
  
  @media screen and (max-width: 768px) {
    .ecoway-header {
      padding: 20px 10px;
    }
  
    .content {
      padding: 10px;
    }
  }
  