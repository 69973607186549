.App {
  text-align: center;
  overflow: hidden;
  
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.footer {
  width: 100px;
  maxHeight: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Adjust the gap as needed */
}

/* You might already have styles for the <a> tags and <img> tags, adjust as necessary */
.footer a img {
  transition: transform 0.2s ease-in-out;
}

.footer a:hover img {
  transform: scale(1.1); /* Slightly enlarges the icons on hover */
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App
{
  background-color: #282c34;
  color: white;


}
.App-header {
  background-color: #282c34;

  min-height: 300vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-y: hidden;
  
}
.newChange {
  position: absolute;
  top: 50px; /* Adjust as needed */
  /* Other styling */
}

.footer-link {
  display: inline-block; /* Ensures the transform applies correctly */
  margin: 0 10px; /* Optional: Adds some space between the icons */
  transition: transform 0.3s ease; /* Smoothly transition the transform property */
}

.footer-link:hover {
  transform: scale(1.1); /* Scales up the link's content (the image) by 10% */
}

.App-footer {
  background-color: #1b1d22;
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  overflow: hidden;
}



.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}











.bigBox {
    display: flex;
    flex-direction: column; /* Adjusted to column for consistency with below media query */
    gap: 0px;
    align-items: center;
}

.container {
  display: flex;
  flex-direction: row; /* or column, depending on your layout */
  margin-left: 30%;
  margin-right: 30%;
  align-items: left; /* This ensures vertical alignment is centered */
  gap: 20px;
}

.back{
  background-position: center; /* Center the background image */
  background-size: cover; /* Cover the entire screen */
  background-repeat: no-repeat; /* Do not repeat the background image */
  min-height: 100vh; /* Minimum height of 100% of the viewport height */
  width: 100vw; /* Width of 100% */
  display: flex;
}

.smaller {
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start; /* Align content to the left */
}

.title {
  font-size: 25pt;
  padding: 0;
}
.image {
  height: 200px;
  width: 300px;
  border-radius: 45px;
  margin: 0;
  position: relative;
}

.quote {
  font-size: 10pt;
  position: relative;
  top: -25px;
}

.text {
  position: relative;
  top: -40px;
  font-size: 10pt;
  text-align: left;
}

.hey
{
  color: #282c34;

  font-size: 30pt;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 35%;
}
.heys
{
  color: #282c34;

  font-size: 50pt;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 39%;
}
.subtitle {
  font-size: 15pt;
  padding: 0;
  position: relative;
  top: -25px;
}

p {
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack children vertically on small screens */
    align-items: center; /* Center align the items for a better mobile experience */
  }
  
  .title, .subtitle, .text, .quote {
  }
  .smaller
  {
    position: relative;
    top: -20px;
   
    align-items: stretch;
  }
}

@media (max-width: 600px) {
  .App-footer {
    justify-content: center; /* Centers items if not already set */
    padding: 0 10px; /* Adjust padding as needed for overall container */
    gap: 0;
  }

  .footer-link {
    margin: 0 0px; /* Reduces the margin between links */
    gap: 100;
    transform: scale(0.5);
    /* No need to adjust scale unless you want to change the size of the items */
  }
}












.stuffAdjust
{
  position: relative;
  top: 7.5%
}



@media (max-width: 600px) {

  .stuffAdjust
{
  position: relative;
  top: 7.5%
}
}




